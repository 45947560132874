<template>
  <v-container fluid>
    <v-col col="12" md="12" lg="12" outlined>
      <v-card class="pa-4 rounded-xl">
        <v-card-title class="justify-left d-flex flex-row pb-1">
          <p>Partido</p>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider color="#75D89F" class="mx-3"></v-divider>
        <v-card-text>
          <!-- <h3 style="color:#083C5F" class="pb-4">Información del Partido</h3> -->
          <v-row>
            <v-col col="12">
              <form-match :match="imatch"></form-match>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-end">
            <v-btn @click="save()" class="ma-3 rounded-lg text-capitalize" dense color="primary">
              Grabar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar v-model="snackbar" :color="color" class="mr-2" top>
      <span class="d-flex justify-center white--text">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>



<script>
import FormMatch from "@/components/forms/FormMatch.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: "MatchAddEdit",
  props: ["id"],
  components: {
    FormMatch,
  },
  watch: {
    match: function (value) {
      // console.log(value)
      if (this.id) {
        this.imatch = _cloneDeep(value);
      }
    },
    matchError: function (value) {
      if (value)
        this.mensaje(true, "error", this.matchError.message, 5000);
    },
    matchSusccesMessage: function (value) {
      if (value) {
        this.mensaje(true, "success", this.matchSusccesMessage, 5000);
        if (!this.id) this.imatch = {};
        this.$router.go(-1);
      }
    },

  },
  computed: {
    ...mapState({
      match: (state) => state.match.match,
      matchError: (state) => state.match.matchError,
      matchSusccesMessage: (state) => state.match.matchSusccesMessage,
    }),
  },
  data: () => ({
    imatch: {
      match_goals:[]
    },
    snackbar: false,
    color: "",
    text: "",
  }),
  methods: {
    ...mapActions(["addMatch", "editMatch", "getMatchById"]),
    save() {
    //   console.log(this.imatch);
      if (this.id) {
        //update
        this.editMatch({ match: this.imatch });
      } else {
        this.addMatch({ match: this.imatch });
        //create
      }
    },
    mensaje(snackbar, color, text) {
      this.snackbar = snackbar;
      this.color = color;
      this.text = text;
    },
    getFetch() {
      // console.log(this.match)
      if (this.id) {
        this.getMatchById({ id: this.id });
      }
    },
  },
  created() {
    this.getFetch();
  },
};
</script>

<style scoped>
</style>