<template>

    <v-container fluid>
        <h3 style="color:#083C5F" class="pb-4">Grupo /Fase</h3>

        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Grupo/Fase</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="match.group_id" :items="group_phases" item-value="id" item-text="name"
                            label="" outlined dense @change="getTeamBySelected(match.group_id)" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <h3 style="color:#083C5F" class="pb-4">Partido</h3>
                <div class="pt-4"></div>
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Local</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="match.local_id" :items="selectTeams" item-value="id" item-text="name"
                            label="" outlined dense />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Vistante</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="match.visitor_id" :items="selectTeams" item-value="id" item-text="name"
                            label="" outlined dense />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Fecha</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-menu v-model="match.menu" :close-on-content-click="false" readonly
                            transition="scale-transition" max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="match_date" v-model="match.match_date" append-icon="mdi-calendar"
                                    outlined label="" readonly dense persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-date-picker v-model="match.date" no-title @input="
    match.menu = false;
match.match_date = formatDate(match.date);
                            " />
                        </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Hora</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-menu ref="menu" v-model="match.menu2" :close-on-content-click="false" readonly
                            transition="scale-transition" :return-value.sync="match.match_time" offset-y
                            max-width="290px" min-width="290px">
                            <template #activator="{ on, attrs }">
                                <v-text-field ref="match_time" v-model="match.match_time"
                                    append-icon="mdi-clock-time-four-outline" outlined label="" readonly dense
                                    persistent-hint v-on="on" v-bind="attrs" />
                            </template>
                            <v-time-picker v-if="match.menu2" v-model="match.match_time" full-width
                                @click:minute="$refs.menu.save(match.match_time)"></v-time-picker>
                        </v-menu>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Estadio</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="match.stadium_id" :items="stadiums" item-value="id" item-text="name" label=""
                            outlined dense />
                    </v-col>
                </v-row>

            </v-col>

            <v-col cols="6">
                <h3 style="color:#083C5F" class="pb-4">Porcentage de partido ganado</h3>
                <div class="pt-4"></div>
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Equipo 1</v-subheader>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="" v-model="match.local_percentage" required outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="5 d-flex justify-start align-center">
                        <p>%</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Equipo 2</v-subheader>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="" v-model="match.visitor_percentage" required outlined dense>
                        </v-text-field>
                    </v-col>
                    <v-col cols="5 d-flex justify-start align-center">
                        <p>%</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Empate</v-subheader>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field label="" v-model="match.tied_percentage" required outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="5 d-flex justify-start align-center">
                        <p>%</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <h3 style="color:#083C5F" class="pb-4">Resultados</h3>
                <div class="pt-4"></div>
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Resultado</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="match.result" :items="match_results" item-value="id" item-text="name"
                            label="" outlined dense />
                    </v-col>
                </v-row>
                <section v-for="(item, index) in local_goals" :key="'local' + index">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader class="d-flex justify-end">Goales local</v-subheader>
                        </v-col>
                        <v-col cols="9">
                            <v-text-field label="" v-model="item.goals" required outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader class="d-flex justify-end">Gol</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-select v-model="item.player_id" :items="local_players" item-value="id"
                                item-text="full_name" label="" outlined dense />
                        </v-col>
                         <v-col cols="2">
                            <v-btn icon color="red" @click="delGol(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </section>
                <v-row class="d-flex justify-end">
                    <v-btn @click="addNewGol('local')" class="ma-3 rounded-lg text-capitalize" dense color="primary">
                        Agregar goles locales
                    </v-btn>

                </v-row>

                <section v-for="(item, index) in visit_goals" :key="'visitor' + index">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader class="d-flex justify-end">Goales visitante</v-subheader>
                        </v-col>
                        <v-col cols="9">
                            <v-text-field label="" v-model="item.goals" required outlined dense>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader class="d-flex justify-end">Gol</v-subheader>
                        </v-col>
                        <v-col cols="7">
                            <v-select v-model="item.player_id" :items="visitor_players" item-value="id"
                                item-text="full_name" label="" outlined dense />
                        </v-col>
                        <v-col cols="2">
                            <v-btn icon color="red" @click="delGol(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </section>
                <v-row class="d-flex justify-end">
                    <v-btn @click="addNewGol('visitor')" class="ma-3 rounded-lg text-capitalize" dense color="primary">
                        Agregar goles visitantes
                    </v-btn>

                </v-row>

            </v-col>

        </v-row>

    </v-container>
</template>

<script>
// import FotoUpload from "@/components/util/Foto.vue";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import mixins from "@/mixins";
// import Files from "@/components/util/File.vue";

export default {
    name: "form-match",
    props: ["match"],
    watch: {
        match(newValue) {
            if (newValue.local_id) {
                this.getPlayerById({ id: this.match.local_id }).then(response => {
                    this.local_players = response.data;
                }).catch(error => {
                    console.log(error);
                });
            }
            if (newValue.visitor_id) {
                this.getPlayerById({ id: this.match.visitor_id }).then(response => {
                    this.visitor_players = response.data;
                }).catch(error => {
                    console.log(error);
                });
            }
        },
    },
    data: () => ({
        local_players: [],
        visitor_players: [],
    }),
    computed: {
        ...mapState({
            selectTeams: (state) => state.team.selectTeams,
            group_phases: (state) => state.util.group_phases,
            stadiums: (state) => state.util.stadiums,
            match_results: (state) => state.util.match_results,
        }),
        local_goals() {
            if (this.match.match_goals) {
                return this.match.match_goals.filter(goal => goal.type == 'local')
            }
            return []
        },
        visit_goals() {
            if (this.match.match_goals) {
                return this.match.match_goals.filter(goal => goal.type == 'visitor')
            }
            return []
        }
    },
    methods: {
        ...mapActions(["fetchAllTeams", "fetchGroupPhases", "fetchStadiums", "fetchGetTeamsByGroupId", "fetchMatchResults", "getPlayerById"]),
        getFetch() {
            // this.fetchAllTeams();
            this.fetchGroupPhases();
            this.fetchStadiums();
            this.fetchMatchResults();


        },
        getTeamBySelected(index) {
            let tmp = this.group_phases.find(item => item.id == index);
            if (tmp.type == 2) {
                this.fetchAllTeams();
            } else {
                this.fetchGetTeamsByGroupId({ id: tmp.id });
            }
        },
        getTeamByMatch(match) {
            if (match.group_id) {
                this.fetchGetTeamsByGroupId({ id: match.group_id });
            } else {
                this.fetchAllTeams();
            }
        },
        addNewGol(type) {
            this.match.match_goals.push({
                key: this.match.match_goals.length+1,
                type: type,
                goals: undefined,
                player_id: undefined
            })
            // console.log(this.match);
        },
        delGol(item) {
            // console.log(data);
            this.match.match_goals.splice(this.match.match_goals.indexOf(item), 1);
        },
    },
    created() {
        this.getTeamByMatch(this.match);
        this.getFetch();
    },
};
</script>

<style scoped>
/* .row {
    margin-top: -10px !important;
} */
</style>